<template>
  <v-footer app padless absolute>
    <v-card flat tile width="100%" class="grey lighten-1 text-center">
      <v-card-text class="white--text">
        &copy; {{ new Date().getFullYear() }} — <strong>Silicon PJ </strong> - version ({{ version }}) - Developed by
        Pol.Maj.Bordin Sangsittisak
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      version: '',
    }
  },
  created() {
    this.version = process.env.VUE_APP_VERSION
  },
}
</script>

<style></style>
