<template>
  <!-- clipped-left ให้เมนูด้านซ้ายอยู่ใต้บาร์ -->
  <v-app-bar app color="indigo darken-4" flat dark clipped-left>
    <v-app-bar-nav-icon @click="$emit('sidemenu')"></v-app-bar-nav-icon>
    <v-img :src="logo" max-height="35" max-width="35" />
    <v-toolbar-title style="overflow: visible">
      {{ title }}
    </v-toolbar-title>
    <!-- <v-container class="py-0 fill-height"> -->
    <v-spacer></v-spacer>

    <!-- DATA FILTERS -->

    <div class="hidden-sm-and-down d-md-flex ">
      <v-select
        :items="bureaus"
        @change="bChanged"
        item-text="name_abbr"
        item-value="id"
        flat
        label="เลือก บช."
        solo-inverted
        :value="bureauId"
        hide-details
        single-line
        clearable
        class="shrink pr-1"
        v-role="[`super-admin`, `commander`]"
      >
      </v-select>

      <v-select
        :items="divisions"
        @change="dChanged"
        item-text="name_abbr"
        item-value="id"
        flat
        label="เลือก บก."
        solo-inverted
        :disabled="divisions.length == 0"
        hide-details
        single-line
        clearable
        class="shrink pr-1"
        v-role="[`super-admin`, `commander`, `bureau-admin`]"
      >
      </v-select>

      <v-select
        :items="stations"
        @change="sChanged"
        item-text="name_abbr"
        item-value="id"
        flat
        label="เลือก สถานี"
        solo-inverted
        :disabled="stations.length == 0"
        hide-details
        single-line
        clearable
        class="shrink pr-1"
        v-role="[`super-admin`, `commander`, `bureau-admin`, `division-admin`]"
      >
      </v-select>
    </div>

    <!-- date picker -->

    <v-menu
      v-model="datemenu"
      ref="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="datesFormat"
          label="เลือกช่วงเวลา"
          prepend-inner-icon="mdi-calendar"
          readonly
          solo-inverted
          v-bind="attrs"
          v-on="on"
          hide-details
          single-line
          clearable
          class="shrink pr-1"
        ></v-text-field>
      </template>
      <v-date-picker range v-model="dates" no-title>
        <v-spacer></v-spacer>
        <v-btn text color="secondary" @click="datemenu = false">
          Cancel
        </v-btn>
        <v-btn text color="primary" @click="$refs.menu.save(dates)">
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>
    <!-- MENU SMALL SCREEN -->

    <v-toolbar-items class="hidden-md-and-up">
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark v-bind="attrs" v-on="on" icon>
            <v-icon>mdi-dots-vertical-circle</v-icon>
          </v-btn>
        </template>
        <v-list class="hidden-md-and-up">
          <v-list-item>
            <v-select
              :items="bureaus"
              @change="bChanged"
              item-text="name_abbr"
              item-value="id"
              flat
              label="เลือก บช."
              solo-inverted
              :value="bureauId"
              hide-details
              single-line
              clearable
              class="pr-1"
              v-role="[`super-admin`, `commander`]"
            >
            </v-select>
          </v-list-item>
          <v-list-item>
            <v-select
              :items="divisions"
              @change="dChanged"
              item-text="name_abbr"
              item-value="id"
              flat
              label="เลือก บก."
              solo-inverted
              :disabled="divisions.length == 0"
              hide-details
              single-line
              clearable
              class="pr-1"
              v-role="[`super-admin`, `commander`, `bureau-admin`]"
            >
            </v-select>
          </v-list-item>
          <v-list-item>
            <v-select
              :items="stations"
              @change="sChanged"
              item-text="name_abbr"
              item-value="id"
              flat
              label="เลือก สถานี"
              solo-inverted
              :disabled="stations.length == 0"
              hide-details
              single-line
              clearable
              class="pr-1"
              v-role="[`super-admin`, `commander`, `bureau-admin`, `division-admin`]"
            >
            </v-select>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import logo from '@/assets/logo.png'
import { mapGetters, mapMutations } from 'vuex'
export default {
  data() {
    return {
      links: [
        { to: '/', title: 'Home' },
        { to: 'dashboard', title: 'DB' },
        { to: 'menu', title: 'menu3' },
        { to: 'menu', title: 'menu4' },
        { to: 'login', title: 'Login' },
        { to: 'about', title: 'About' },
      ],
      logo: logo,
      title: 'TPCC',
      datemenu: false,
      datemenu2: false,
      dates: [],
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    datesFormat: {
      get() {
        if (Array.isArray(this.dates)) {
          return this.dates
            .map(d =>
              this.moment(d)
                .add(543, 'year')
                .format('D MMMYYYY')
            )
            .join('-')
        }
        return null
      },
      set(v) {
        this.dates = v
      },
    },
    ...mapGetters('Appfilter', ['bureaus', 'bureauId', 'divisionId', 'stationId', 'divisions', 'stations']),
    ...mapGetters('User', ['role']),
  },
  mounted() {
    if (this.role == 'super-admin' || this.role == 'commander') this.$store.dispatch('Appfilter/getBureau')
    else if (this.role == 'bureau-admin') this.$store.dispatch('Appfilter/getDivision')
    else if (this.role == 'division-admin') this.$store.dispatch('Appfilter/getStation')
  },
  watch: {
    dates(v) {
      this.setDatesSelected(v)
      if (v != null) {
        this.setDateStart(v[0])
        this.setDateEnd(v[1])
      } else {
        this.setDateStart(null)
        this.setDateEnd(null)
      }
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/')
      })
    },
    bChanged(v) {
      this.setBureau(v)
      this.setDivision(null)
      this.setDivisions([])
      this.setStation(null)
      this.setStations([])
      if (this.bureauId == null || this.bureauId == '') return
      this.$store.dispatch('Appfilter/getDivision')
    },
    dChanged(v) {
      this.setDivision(v)
      this.setStation(null)
      this.setStations([])
      if (this.divisionId == null || this.divisionId == '') return
      this.$store.dispatch('Appfilter/getStation')
    },
    sChanged(v) {
      this.setStation(v)
    },
    ...mapMutations('Appfilter', [
      'setBureau',
      'setDivision',
      'setStation',
      'setDivisions',
      'setStations',
      'setDateStart',
      'setDateEnd',
      'setDatesSelected',
    ]),
  },
}
</script>

<style scoped></style>
