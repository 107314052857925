<template>
  <!-- clipped ให้เมนูอยู่ใต้บาร์ -->
  <v-navigation-drawer app clipped v-model="drawer" :mini-variant.sync="menumini" :expand-on-hover="eoh">
    <!-- user info -->
    <v-list-item class="px-2" two-line to="/profile">
      <v-list-item-avatar>
        <v-avatar color="red">
          <span class="white--text headline" v-if="!$store.getters['User/avatarPicture']">
            {{ $store.getters['User/avatar'] }}
          </span>
          <v-img v-else :src="$store.getters['User/avatarPicture']" aspect-ratio="1"></v-img>
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ $store.getters['User/name'] }}</v-list-item-title>
        <v-list-item-subtitle>
          {{ $store.getters['User/stationName'] }}
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          <span style="cursor: pointer; text-decoration: underline;">แก้ไขข้อมูล/ขอเปลี่ยนสิทธิ์</span>
        </v-list-item-subtitle>
        <!-- <v-list-item-subtitle>
          <v-chip color="blue" dark>{{ $store.getters['User/role'] }}</v-chip>
        </v-list-item-subtitle> -->
      </v-list-item-content>
    </v-list-item>
    


    <!-- main menu -->
    <v-list dense nav>
      <v-subheader v-show="!mini">เมนูหลัก</v-subheader>
      <v-list-item v-for="item in menus" :key="item.title" :to="item.to" v-role="item.roles">
        <v-list-item-icon>
          <v-badge
            bordered
            color="error"
            :content="commandApproval"
            :value="commandApproval"
            overlap
            v-if="item.showBadge"
          >
            <v-icon :color="item.color || 'grey darken-4'">{{ item.icon }}</v-icon>
          </v-badge>
          <v-icon :color="item.color || 'grey darken-4'" v-else>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list dense nav>
      <v-list-item v-for="item in reportMinus" :key="item.title" :to="item.to" @click="item.click">
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <!-- admin menu -->
    <v-list dense nav v-role="[`super-admin`]">
      <v-subheader v-show="!mini">Administrator</v-subheader>
      <v-list-item v-for="item in adminMenus" :key="item.title" :to="item.to">
        <v-list-item-icon>
          <v-badge bordered color="error" :content="roleApproval" :value="roleApproval" overlap v-if="item.showBadge">
            <v-icon>{{ item.icon }}</v-icon>
          </v-badge>
          <v-icon v-else>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <!-- other menu -->
    <v-list dense nav>
      <v-list-item v-for="item in otherMenus" :key="item.title" :to="item.to" @click="item.click">
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  methods: {
    logout() {
      this.$store.dispatch('Auth/logout').then(this.$router.push('/login'))
    },
    mo() {
      console.log('mo')
      this.getRoleApproval()
      this.getCommandApproval()
    },
    setMenuMini() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          this.menumini = false
          return
        case 'sm':
          this.menumini = false
          return
        case 'md':
          this.menumini = false
          return
        case 'lg':
          this.menumini = true
          return
        case 'xl':
          this.menumini = true
          return
        default:
          this.menumini = true
          return
      }
    },
    ...mapActions('Appfilter', ['getRoleApproval', 'getCommandApproval']),
  },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.getRoleApproval()
    this.getCommandApproval()
    this.setMenuMini()
  },
  computed: {
    drawer: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    eoh() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return false
        case 'sm':
          return false
        case 'md':
          return false
        case 'lg':
          return true
        case 'xl':
          return true
        default:
          return true
      }
    },
    mini: {
      get() {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            return false
          case 'sm':
            return false
          case 'md':
            return false
          case 'lg':
            return true
          case 'xl':
            return true
          default:
            return true
        }
      },
      set(v) {},
    },
    ...mapGetters('Appfilter', ['roleApproval', 'commandApproval']),
    ...mapGetters('User', ['role']),
  },
  data() {
    return {
      menumini: true,
      menus: [
        {
          title: 'Dashboard',
          icon: 'mdi-monitor-dashboard',
          to: '/',
          roles: ['*'],
        },
        {
          title: 'จุดตรวจวันนี้',
          icon: 'mdi-traffic-cone',
          to: '/today',
          color: 'red',
          roles: [`super-admin`, `commander`, `bureau-admin`, `division-admin`, `station-admin`],
        },
        {
          title: 'จุดตรวจทั้งหมด',
          icon: 'mdi-traffic-cone',
          to: '/all',
          color: 'blue darken-3',
          roles: ['*'],
        },
        {
          title: 'อนุมัติคำสั่ง',
          icon: 'mdi-check',
          to: '/command-approval',
          color: 'green',
          roles: [`super-admin`, `division-admin`],
          showBadge: true,
        },
        {
          title: 'คำสั่ง',
          icon: 'mdi-text-box',
          to: '/command',
          roles: [`super-admin`, `bureau-admin`, `division-admin`, `station-admin`],
        },
        {
          title: 'เจ้าหน้าที่',
          icon: 'mdi-account-group-outline',
          to: '/officer',
          roles: ['super-admin', 'bureau-admin', 'division-admin', 'station-admin'],
        },
        {
          title: 'กล้อง CCTV',
          icon: 'mdi-cctv',
          to: '/cctv',
          roles: ['super-admin', 'bureau-admin', 'division-admin', 'station-admin'],
        },
        {
          title: 'ผลตรวจแอลกอฮอล์',
          icon: 'mdi-bottle-wine-outline',
          to: '/drunkreport',
          roles: ['super-admin', 'bureau-admin', 'division-admin', 'station-admin'],
        },
        {
          title: 'ผลการปฏิบัติ',
          icon: 'mdi-handcuffs',
          to: '/arrestreport',
          roles: ['*'],
        },
        {
          title: 'ตรวจ/ประเมินความพร้อมจุดตรวจ',
          icon: 'mdi-order-bool-ascending-variant',
          to: '/evaluation',
          color: 'green',
          roles: ['super-admin', 'bureau-admin', 'division-admin', 'station-admin', 'commander', 'evaluator'],
        },
        {
          title: 'ผลการประเมินความพึงพอใจจากประชาชน',
          icon: 'mdi-account-multiple-check-outline',
          to: '/satisfactionreport',
          roles: ['*'],
        },
        
        {
          title: 'PTM',
          icon: 'mdi-ticket',
          to: '/ptmreport',
          roles: ['*'],
        },
        {
          title: 'ด่านตรวจถาวร',
          icon: 'mdi-sign-caution',
          to: '/pcp',
          roles: ['super-admin', 'bureau-admin', 'division-admin', 'station-admin'],
        },
      ],
      reportMinus: [
        {
          title: 'รายงานจำนวนรถเรียกตรวจ',
          icon: 'mdi-file-chart',
          to: '/arrestedvehiclereport',
          roles: ['*'],
        },
      ],
      otherMenus: [
        {
          title: 'About',
          icon: 'mdi-information',
          to: '/about',
          click: () => {},
        },
        {
          title: 'ออกจากระบบ',
          icon: 'mdi-lock',
          to: null,
          click: this.logout,
        },
      ],
      adminMenus: [
        {
          title: 'อนุมัติสิทธิ์',
          icon: 'mdi-checkbox-marked-outline',
          to: '/role-approve',
          showBadge: true,
        },
        {
          title: 'User',
          icon: 'mdi-badge-account',
          to: '/user',
        },
      ],
    }
  },
  watch: {
    menumini() {
      if (this.role == 'super-admin') this.getRoleApproval()
      if (this.role == 'super-admin' || this.role == 'division-admin') this.getCommandApproval()
    },
  },
}
</script>

<style></style>
