<template>
  <v-app id="inspire">
    <top-bar @sidemenu="menu = !menu" />
    <navi v-model="menu" />
    <v-main>
      <router-view />
    </v-main>
    <!-- </v-app> -->
    <footer-bar />
  </v-app>
</template>

<script>
import topBar from './sections/topbar'
import footerBar from './sections/footerbar'
import navi from './sections/navi'
export default {
  components: {
    topBar,
    footerBar,
    navi,
  },
  data() {
    return {
      menu: true,
    }
  },
}
</script>

<style scss>
/* .v-application {
  font-family: 'Kanit' !important;
} */
</style>
